import { TeamRole } from '@index/interfaces';

import { GthModel } from './model';

export class GthTeamRoleModel extends GthModel<TeamRole> {
  get teamRole(): TeamRole {
    return this.model;
  }

  get uid() {
    return this.teamRole.uid;
  }

  get id() {
    return this.teamRole.id;
  }

  get label() {
    return this.teamRole.label;
  }

  get item() {
    return this.teamRole.item;
  }

  constructor(id: string, model: TeamRole) {
    super(id, model);
  }
}
